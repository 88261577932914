import * as React from "react"
import styled from "styled-components"

import Contentpage from "../components/templates/Contentpage"
import Seo from '../components/atoms/Seo'
import Row from '../components/molecules/Row'
import Column from "../components/atoms/Column"
import Box from "../components/atoms/Box"
import Divider from "../components/atoms/Divider"
import Title from '../components/atoms/Title'

const StyledContainer = styled.div`
    width: 100%;
    position: relative;
    text-align: center;
    margin-bottom: 30px;

    &:nth-child(3n+1) {
        clear: both;
    }

    &:nth-child(3n) {
        border-right: none;
    }

    @media (min-width: 1024px) {
        width: 33.3333333333%;
        border-right: 2px solid #9f9d96;
    }
`;
const StyledParagraph = styled.p`
    text-align: center;
`;
const StyledLine = styled.p`
    width: 80%;
    height: 2px;
    margin: 0 auto;
    padding: 16px 0;
    border-bottom: 2px solid #9f9d96;
`;

const TransactionsPage = () => {
    const Data = [
        {
            title: 'Social Media',
            description: 'SAFE',
            value: '1.250.000',
            year: '2021',
        },
        {
            title: 'Medical Information',
            description: 'Preferred Stock',
            value: '3.000.000',
            year: '2021',
        },
        {
            title: 'Drug Development',
            description: 'Convertible Debt',
            value: '3.000.000',
            year: '2020',
        },
        {
            title: 'Venture Debt Lender',
            description: 'LLC Units',
            value: '2.000.000',
            year: '2020',
        },
        {
            title: 'Specialty Lender',
            description: 'LLC Interests',
            value: '2.000.000',
            year: '-',
        },
        {
            title: 'Chemicals',
            description: 'Preferred Stock',
            value: '8.500.000',
            year: '2020',
        },
        {
            title: 'Biotech Company',
            description: 'Private Tender Offer',
            value: '28.000.000',
            year: '2019',
        },
        {
            title: 'Biotech Company',
            description: 'Private Tender Offer',
            value: '15.700.000',
            year: '2019',
        },
        {
            title: 'Biotech Company',
            description: 'Common Stock',
            value: '15.900.000',
            year: '2019',
        },
        {
            title: 'Clinical Research Services',
            description: 'Asset Purchase',
            value: '3.300.000',
            year: '2019',
        },
        {
            title: 'Maritime Business',
            description: 'Asset Purchase',
            value: '5.500.000',
            year: '2019',
        },
        {
            title: 'Virtual School',
            description: 'LLC Units',
            value: '5.000.000',
            year: '2019',
        },
        {
            title: 'High Yield Debt Fund',
            description: 'LLC Units',
            value: '2.000.000',
            year: '2018',
        },
        {
            title: 'Prepaid Credit Card Company',
            description: 'Convertible Notes',
            value: '2.000.000',
            year: '2018',
        },
        {
            title: 'Education Technology &amp; Services',
            description: 'Asset Purchase',
            value: '4.000.000',
            year: '2018',
        },
        {
            title: 'Artificial Intelligence Technology',
            description: 'Preferred Stock',
            value: '20.000.000',
            year: '2017',
        },
        {
            title: 'Educational Test Preparation',
            description: 'Asset Purchase',
            value: '5.250.000',
            year: '2017',
        },
        {
            title: 'Internet Advertising Technology',
            description: 'Preferred Units',
            value: '3.000.000',
            year: '2017',
        },
        {
            title: 'Credit Card Technology',
            description: 'Convertible Debt',
            value: '2.500.000',
            year: '2017',
        },
        {
            title: 'Travel Website/Services',
            description: 'LLC Units',
            value: '550.000',
            year: '2017',
        },
        {
            title: 'Waste Services',
            description: 'Asset Sale',
            value: '5.000,000',
            year: '2017',
        },
        {
            title: 'Apparel Company',
            description: 'Secured Note',
            value: '3.650.000',
            year: '2016',
        },
        {
            title: 'Real Estate Software',
            description: 'Convertible Note',
            value: '500.000',
            year: '2016',
        },
        {
            title: 'Charity Technology Platform',
            description: 'Convertible Note',
            value: '1.500.000',
            year: '2016',
        },
        {
            title: 'Venture Fund',
            description: 'LP Interests',
            value: '10.000.000',
            year: '2016',
        },
        {
            title: 'Drone Tracking',
            description: 'LLC Units',
            value: '250.000',
            year: '2016',
        },
        {
            title: 'Premium Ice Cream Company',
            description: 'Preferred Stock',
            value: '1.000.000',
            year: '2016',
        },
        {
            title: 'Nursing Home',
            description: 'Asset Sale',
            value: '11.100.000',
            year: '2016',
        },
        {
            title: 'Document Delivery Platform',
            description: 'LLC Units',
            value: '2.000.000',
            year: '2016',
        },
        {
            title: 'Retail Food Company',
            description: 'Convertible Notes',
            value: '200.000',
            year: '2015',
        },
        {
            title: 'Private Equity Fund',
            description: 'Units',
            value: '10.000.000',
            year: '2015',
        },
        {
            title: 'Document Delivery Platform',
            description: 'Convertible Notes',
            value: '200.000',
            year: '2015',
        },
        {
            title: 'Brewery',
            description: 'LLC Units',
            value: '325.000',
            year: '2015',
        },
        {
            title: 'Healthcare Company',
            description: 'Convertible Notes',
            value: '540.000',
            year: '2015',
        },
        {
            title: 'Real Estate Software',
            description: 'Convertible Notes',
            value: '700.000',
            year: '2015',
        },
        {
            title: 'Charity Software Platform',
            description: 'Preferred Stock',
            value: '1.150.000',
            year: '2015',
        },
        {
            title: 'Apparel Company',
            description: 'Preferred Stock',
            value: '10.000.000',
            year: '2015',
        },
        {
            title: 'Apparel Company',
            description: 'Secured Note',
            value: '2.000.000',
            year: '2015',
        },
        {
            title: 'Government Contracting Software',
            description: 'Preferred Stock',
            value: '9.800.000',
            year: '2015',
        },
        {
            title: 'Real Estate Investment',
            description: 'LLC Units',
            value: '5.800.000',
            year: '2015',
        },
        {
            title: 'Seed Investment Vehicle',
            description: 'Preferred Stock',
            value: '1.500.000',
            year: '2014',
        },
        {
            title: 'Internet Security',
            description: 'Acquisition',
            value: '14.000.000',
            year: '2014',
        },
        {
            title: 'Internet Security',
            description: 'Loan',
            value: '30.000.000',
            year: '2014',
        },
        {
            title: 'Apparel Company',
            description: 'Preferred Stock',
            value: '1.000.000',
            year: '2014',
        },
        {
            title: 'Healthcare Internet Platform',
            description: 'Preferred Stock',
            value: '1.000.000',
            year: '2014',
        },
        {
            title: 'Satellite Services',
            description: 'Company Sale',
            value: '3.900.000',
            year: '2014',
        },
        {
            title: 'Real Estate Software',
            description: 'Convertible Notes',
            value: '400.000',
            year: '2014',
        },
        {
            title: 'Premium Ice Cream Company',
            description: 'Common Stock',
            value: '250.000',
            year: '2014',
        },
        {
            title: 'Investment Fund',
            description: 'LLC Units',
            value: '5.000.000',
            year: '2014',
        },
        {
            title: 'Retail Food Company',
            description: 'LLC Units',
            value: '250.000',
            year: '2014',
        },
        {
            title: 'Internet Advertising Technology',
            description: 'Preferred Stock',
            value: '6.500.000',
            year: '2013',
        },
        {
            title: 'Education Technology Platform',
            description: 'Convertible Notes',
            value: '2.000.000',
            year: '2013',
        },
        {
            title: 'Intent Content &amp; Technology',
            description: 'Corporate Acquisition',
            value: '79.000.000',
            year: '2012',
        },
        {
            title: 'OTT Technology',
            description: 'Corporate Acquisition',
            value: '7.000.000',
            year: '2012',
        },
        {
            title: 'OTT Content &amp; Technology',
            description: 'Corporate Acquisition',
            value: '52.000.000',
            year: '2012',
        },
        {
            title: 'OTT Content &amp; Technology',
            description: 'Asset Sale',
            value: '3.000.000',
            year: '2012',
        },
        {
            title: 'Surgical Product',
            description: 'Asset Sale',
            value: '7.500.000',
            year: '2012',
        },
        {
            title: 'OTT Technology &amp; Content',
            description: 'Corporate Acquisition',
            value: '12.000.000',
            year: '2012',
        },
        {
            title: 'Drug Research Consulting',
            description: 'Corporate Acquisition',
            value: '1.250.000',
            year: '2012',
        },
        {
            title: 'IPTV Company',
            description: 'Corporate Acquisition',
            value: '3.600.000',
            year: '2011',
        },
        {
            title: 'OTT Content Company',
            description: 'Corporate Acquisition',
            value: '7.200.000',
            year: '2011',
        },
        {
            title: 'IPTV Company',
            description: 'Corporate Acquisition',
            value: '10.500.000',
            year: '2010',
        },
        {
            title: 'Video Asset Management',
            description: 'Systems Integrator',
            value: '10.630.000',
            year: '2010',
        },
        {
            title: 'IPTV Company',
            description: 'Corporate Acquisition',
            value: '6.500.000',
            year: '2010',
        },
        {
            title: 'IPTV Company',
            description: 'Corporate Acquisition',
            value: '4.000.000',
            year: '2010',
        },
        {
            title: 'Software Company',
            description: 'Corporate Acquisition',
            value: '20.000.000',
            year: '2009',
        },
        {
            title: 'Family Food and Entertainment Complex',
            description: 'LLC Interests',
            value: '3.500.000',
            year: '2008',
        },
        {
            title: 'Mezzanine Debt Fund',
            description: 'LP Interests',
            value: '25.000.000',
            year: '2008',
        },
        {
            title: 'Grid Computing',
            description: 'Preferred Stock',
            value: '15.000.000',
            year: '2008',
        },
        {
            title: 'Internet Media Company',
            description: 'Preferred Stock',
            value: '22.500.000',
            year: '2008',
        },
        {
            title: 'Grid Computing',
            description: 'Preferred Stock',
            value: '4.300.000',
            year: '2008',
        },
        {
            title: 'Integrated Wireless Marketing Retail Solutions',
            description: 'Preferred Stock',
            value: '17.000.000',
            year: '2007',
        },
        {
            title: 'IPTV Content Company',
            description: 'Corporate Acquisition',
            value: '7.500.000',
            year: '2007',
        },
        {
            title: 'Mobile and Online Telephony',
            description: 'Preferred Stock',
            value: '4.200.000',
            year: '2007',
        },
        {
            title: 'IPTV Content &amp; Platform',
            description: 'Common Stock',
            value: '9.500.000',
            year: '2007',
        },
        {
            title: 'Internet Media Company',
            description: 'Preferred Stock',
            value: '21.000.000',
            year: '2007',
        },
        {
            title: 'Wireless Communication Services',
            description: 'Preferred Stock',
            value: '5.000.000',
            year: '2007',
        },
        {
            title: 'China Investment Vehicle',
            description: 'Preferred Stock',
            value: '10.000.000',
            year: '2007',
        },
        {
            title: 'Advertising Technology',
            description: 'Preferred Stock',
            value: '46.000.000',
            year: '2006',
        },
        {
            title: 'Internet of Things',
            description: 'Preferred Stock',
            value: '15.000.000',
            year: '2006',
        }
    ];

    const ListElements = Data.map((item) => {
        return (
            <StyledContainer>
                <Column>
                    <Title level={4}>{item.title}</Title>
                    <StyledParagraph>{item.description}</StyledParagraph>
                    <StyledParagraph>{item.value}</StyledParagraph>
                    <StyledParagraph>{item.year}</StyledParagraph>
                </Column>
                {item.title && <StyledLine />}
            </StyledContainer>
        );
    });

  return (
    <Contentpage>
      <Seo
      title='Transactions'
      description='David Pedley has been involved in over $1 Billion in Financing and M&A Transactions some of the transactions that he has worked on includes'
      />
      
      <Row>
          <Column size='100%'>
            <Box color='orangepattern'>
            DAVID PEDLEY HAS BEEN INVOLVED IN OVER $1 BILLION<br />
            IN FINANCING AND M&A TRANSACTIONS<br />
            SOME OF THE TRANSACTIONS THAT HE HAS WORKED ON INCLUDE:
            </Box>
          </Column>
      </Row>

      <Divider />

      <Row wrap={true}>
          {ListElements}
      </Row>
    </Contentpage>
  )
}

export default TransactionsPage
